import React from 'react'
import { Link } from 'gatsby'
import { DefaultLayout } from '../components/Layouts'
import PageTitle from '../components/PageTitle'
import Meta from '../components/Meta'

const AboutPage = () => {
  return (
    <DefaultLayout>
      <Meta title="About" />
      <div className="max-w-2xl mx-auto">
        <PageTitle>About</PageTitle>
        <p>
          <strong>I’ve been told I wear many hats.</strong> I’m passionate about
          digital media, typography, and working with clients who care deeply
          about their projects. Though my background is primarily in design, I
          also work as a front-end web developer, consultant, and educator. I
          love exploring technology and experimenting with new techniques to
          make my clients’ work shine. When I’m not working, I enjoy cooking,
          travel, and photography. I also contribute to several community and
          open-source projects.
        </p>
        <h2>Academic History</h2>
        <p>
          I graduated from the Pratt Institute in 2012 with a MS in
          Communications Design and the International Academy of Design &
          Technology’s Chicago campus in June 2009 with a BFA in Advertising
          Design; I put a heavy emphasis on typography with my electives and
          self-guided projects in each school.
        </p>
        <p>
          At IADT, I explored hand-lettering and craftsmanship (mainly
          bookbinding and packaging). At Pratt, I focused more on the
          readability and usability of type (especially in a digital
          environment). In both cases, I read a lot of books, drank a ton of
          tea, and doodled through many, many notebooks.
        </p>
        <h2>Professional Experience</h2>
        <p>
          My work doesn't fit into a standard timeline. I value the lasting
          relationships I've built with some clients, and many projects overlap.
          As a contractor, I am excellent at managing multiple ongoing projects
          for multiple teams&thinsp;&mdash;&thinsp;and as a friend, I still pour
          my heart into each one.
        </p>
        <p>
          <Link to="resume">View my resume</Link> to learn more about my
          background, the companies I have worked with, and the skills I can
          bring to your organization.
        </p>
      </div>
    </DefaultLayout>
  )
}

export default AboutPage
